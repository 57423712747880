<template>
  <div>
    <index></index>
  </div>
</template>

<script>
import index from '@/views/Index.vue'

export default {
  name: 'App',
  components: {index}
}
</script>

<style>

</style>
