import VueRouter from "vue-router";
import Index from '@/views/Index'
import Vue from "vue";

Vue.use(VueRouter)

export default new VueRouter({
    routes : [
        {
            path: '/',
            name: 'index',
            component: Index,
            meta: {
                title: 'QCS公众号二维码生成'
            }
        }
    ]
})
