import http from '@/untils/http'
import QS from 'qs'
import base from "@/untils/base";


export default {
    // userRejister(data) {
    //     return http({
    //         url: `${base.url}/users/register`,
    //         method: 'post',
    //         data: QS.stringify(data)
    //     })
    // },
    // userInfo() {
    //     return axios({
    //         url: `${base.url}/profile/all`,
    //         method: 'get'
    //     })
    // }
    getQr(id, lot) {
        return http({
            url: `${base.url}/wx/getQrCode`,
            method: 'get',
            params: {id, lot}
        })
    }
}
