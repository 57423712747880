<template>
  <div class="app">
    货号：<input type="text" v-model:value="id">
    <br>
    批号：<input type="text" v-model:value="lot">
    <br>
    <button @click="test1">获取二维码</button>
    <br>
    <img :src="imgUrl" alt="">
  </div>
</template>

<script>
import indexAPI from '@/api/index'

export default {
  name: "Index",
  data() {
    return {
      imgUrl: '',
      id: '',
      lot: ''
    }
  },
  methods: {
    test1() {
      if (this.id === '' || this.lot === '') {
        alert('必须输入产品的货号和批号')
        return false
      }
      indexAPI.getQr(this.id, this.lot)
          .then(response => {
            this.imgUrl = response.data
          })
    }
  }
}
</script>

<style scoped>
  .app button {
    margin-top: 10px;
    margin-left: 90px;
  }
</style>
